<template>
  <div class="h-100 w-100 d-flex flex-column container-fluid card-panel bg-white shadow-sm shadow-hover" id="div-main">
    <template v-if="isLoading">
      <BaseLoading />
    </template>
    <template v-else>
      <div class="row ocultar-xls">
        <div class="col-12 pb-2">
          <div class="container-fluid card-panel bg-white shadow-hover shadow-sm mt-0">
            <h1 class="h1">Relatório de Conferência do Balanço {{ idBalanco }} - {{ descricaoBalanco }}</h1>
          </div>
        </div>
      </div>
      <div class="row ocultar-xls">
        <div class="col-12 pb-2">
          <div class="container-fluid card-panel bg-white shadow-hover shadow-sm mt-0">
            <div class="row">
              <div class="col-12">
                <div class="margin-descricao">
                  <ul>
                    <li
                      >{{ DadosConferenciaBalanco.TotalProdutos }}
                      <span v-if="DadosConferenciaBalanco.TotalProdutos <= 1"> produto encontrado.</span>
                      <span v-if="DadosConferenciaBalanco.TotalProdutos > 1"> produtos encontrados.</span>
                    </li>
                    <li class="text-danger"
                      >{{ DadosConferenciaBalanco.TotalDivergente }}
                      <span v-if="DadosConferenciaBalanco.TotalDivergente <= 1"> produto com divergência encontrada.</span>
                      <span v-if="DadosConferenciaBalanco.TotalDivergente > 1"> produtos com divergências encontradas.</span>
                    </li>
                    <li
                      >{{ DadosConferenciaBalanco.EntradasExecutadas }}
                      <span v-if="DadosConferenciaBalanco.EntradasExecutadas <= 1"> entrada executada </span>
                      <span v-if="DadosConferenciaBalanco.EntradasExecutadas > 1"> entradas executadas </span>
                      (quantidade do balanço maior que a quantidade do ERP).
                    </li>
                    <li
                      >{{ DadosConferenciaBalanco.SaidasExecutadas }}
                      <span v-if="DadosConferenciaBalanco.SaidasExecutadas <= 1"> saída executada </span>
                      <span v-if="DadosConferenciaBalanco.SaidasExecutadas > 1"> saídas executadas </span>
                      (quantidade do balanço menor que a quantidade do ERP).
                    </li>
                  </ul>
                </div>
              </div>
            </div>            
            <div class="col-12" v-if="DadosConferenciaBalanco.ExisteSerial">
              <div class="custom-control custom-checkbox">
                <input 
                  class="custom-control-input pt-2" 
                  id="movimentacaoPorSerial" 
                  type="checkbox" 
                  @change="carregaDadosDoRalatorioDeCritica().then(() => {})"
                  v-model="movimentacaoPorSerial" />
                <label class="custom-control-label" for="movimentacaoPorSerial">Apresentar as movimentações por {{ DadosConferenciaBalanco.LegendaControle }}</label>
              </div>
            </div>
            <div class="row flex-grow-1 mt-3">
              <div class="col-12">
                <toogle-panel title="Produtos Divergentes">
                  <div
                    class="table-responsive mb-3"
                    :style="'overflow-y: auto; overflow-x: hidden;'"
                    v-if="DadosConferenciaBalanco.DadosComDivergenciaConferenciaBalanco.length > 0"
                  >
                    <div class="col-12">
                      <table class="table table-sm table-striped table-hover lmx-tabela-relatorio" cellspacing="1">
                        <thead>
                          <tr>
                            <th class="text-left text-nowrap">Código</th>
                            <th class="text-left text-nowrap">Produto</th>
                            <th class="text-left text-nowrap">Código Auxiliar</th>
                            <th class="text-left text-nowrap">Referência</th>
                            <th class="text-left text-nowrap" v-if="movimentacaoPorSerial">{{ DadosConferenciaBalanco.LegendaControle }}</th>
                            <th class="text-left text-nowrap">Saldo ERP</th>
                            <th v-if="DadosEmpresas.ProdutosControlaLote" class="text-left">Saldo Lote</th>
                            <th class="text-left text-nowrap">Quantidade Balanço</th>
                            <template v-if="DadosEmpresas.ProdutosControlaLote">
                              <th class="text-center text-nowrap">Lote</th>
                              <th class="text-center text-nowrap">Data de Fabricação</th>
                              <th class="text-center text-nowrap">Data de Validade</th>
                            </template>
                            <th class="text-left text-nowrap">Tipo de Ajuste</th>
                            <th class="text-left text-nowrap">Quantidade</th>
                            <th class="text-left text-nowrap">Valor Produto</th>
                            <th class="text-left text-nowrap">Valor Divergente</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(
                              DadosComDivergencia, idx
                            ) in DadosConferenciaBalanco.DadosComDivergenciaConferenciaBalanco.slice(
                              indexInicialComDivergencia,
                              indexFinalComDivergencia,
                            )"
                            :key="idx"
                          >
                            <td class="text-left">{{ DadosComDivergencia.CodigoProduto }}</td>
                            <td class="text-left">{{ DadosComDivergencia.Nome }}</td>
                            <td class="text-left">{{ DadosComDivergencia.CodigoAuxiliar }}</td>
                            <td class="text-left">{{ DadosComDivergencia.Referencia }}</td>
                            <td class="text-left" v-if="movimentacaoPorSerial">
                              {{ DadosComDivergencia.Serial == null ? '-' : DadosComDivergencia.Serial}}
                            </td>
                            <td class="text-left">{{ DadosComDivergencia.SaldoAnteriorERP }}</td>
                            <td v-if="DadosEmpresas.ProdutosControlaLote" class="text-left">
                              {{ DadosComDivergencia.Lote == null ? '-' : DadosComDivergencia.SaldoAnteriorLote }}
                            </td>
                            <td class="text-left">{{ DadosComDivergencia.QuantidadeTotalConferencia }}</td>
                            <template v-if="DadosEmpresas.ProdutosControlaLote">
                              <td class="text-center">
                                {{ DadosComDivergencia.Lote == null ? '-' : DadosComDivergencia.Lote }}
                              </td>
                              <td class="text-center">
                                {{ DadosComDivergencia.DataFabricacao == null ? '-' : formatarDataPTBR(DadosComDivergencia.DataFabricacao) }}
                              </td>
                              <td class="text-center">
                                {{ DadosComDivergencia.DataVencimento == null ? '-' : formatarDataPTBR(DadosComDivergencia.DataVencimento) }}
                              </td>
                            </template>
                            <td class="text-left">{{ DadosComDivergencia.TipoAjuste }}</td>
                            <td class="text-left">{{ DadosComDivergencia.QuantidadeAjuste }}</td>
                            <td class="text-left"
                              >R${{ DadosComDivergencia.ValorProduto.toLocaleString('pt-br', { minimumFractionDigits: 2 }) }}</td
                            >
                            <td class="text-left"
                              >R${{
                                DadosComDivergencia.ValorDivergente.toLocaleString('pt-br', { minimumFractionDigits: 2 })
                              }}</td
                            >
                          </tr>
                        </tbody>
                      </table>
                      <div class="d-flex row justify-content-left ml-1" v-if="totalPaginasComDivergencia > 1">
                        <template v-for="(pagina, index) in totalPaginasComDivergencia">
                          <button
                            v-if="verificarSeExibeBotaoPaginaComDivergencia(pagina)"
                            :key="index"
                            class="btn btn-default"
                            :class="{ active: pagina == paginacaoDivergente.paginaAtual, 'm-1': true }"
                            @click="paginacaoDivergente.paginaAtual = pagina"
                          >
                            {{ pagina }}
                          </button>
                          <span v-else-if="verificarSeExibeBotaoPaginaComDivergencia(pagina - 1)" :key="index" class="m-1"
                            >...</span
                          >
                        </template>
                      </div>
                      <div class="row mt-2">
                        <div class="col-12 col-md-4">
                          <small class="text-muted pl-2">
                            Qtde. de Registros:
                            <b>{{ DadosConferenciaBalanco.TotalDivergente }}</b>
                          </small>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="table-responsive mb-3"
                    :style="'overflow-y: auto; overflow-x: hidden;'"
                    v-if="DadosConferenciaBalanco.DadosComDivergenciaConferenciaBalanco.length <= 0"
                  >
                    <label class="margin-left">Nenhum produto encontrado.</label>
                  </div>
                </toogle-panel>
              </div>
            </div>
            <div class="row flex-grow-1 mt-3">
              <div class="col-12">
                <toogle-panel title="Produtos sem Divergência">
                  <div
                    class="table-responsive mb-3"
                    :style="'overflow-y: auto; overflow-x: hidden;'"
                    v-if="DadosConferenciaBalanco.DadosSemDivergenciaConferenciaBalanco.length > 0"
                  >
                    <div class="col-12">
                      <table class="table table-sm table-striped table-hover lmx-tabela-relatorio" cellspacing="1">
                        <thead>
                          <tr>
                            <th class="text-left text-nowrap">Código</th>
                            <th class="text-left text-nowrap">Produto</th>
                            <th class="text-left text-nowrap">Código Auxiliar</th>
                            <th class="text-left text-nowrap">Referência</th>
                            <th class="text-left text-nowrap" v-if="movimentacaoPorSerial">{{ DadosConferenciaBalanco.LegendaControle }}</th>
                            <th class="text-left text-nowrap">Saldo ERP</th>
                            <th v-if="DadosEmpresas.ProdutosControlaLote" class="text-left">Saldo Lote</th>
                            <th class="text-left text-nowrap">Quantidade Balanço</th>
                            <template v-if="DadosEmpresas.ProdutosControlaLote">
                              <th class="text-center text-nowrap">Lote</th>
                              <th class="text-center text-nowrap">Data de Fabricação</th>
                              <th class="text-center text-nowrap">Data de Validade</th>
                            </template>
                            <th class="text-left text-nowrap">Valor Produto</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(
                              DadosSemDivergencia, idx
                            ) in DadosConferenciaBalanco.DadosSemDivergenciaConferenciaBalanco.slice(
                              indexInicialSemDivergencia,
                              indexFinalSemDivergencia,
                            )"
                            :key="idx"
                          >
                            <td class="text-left">{{ DadosSemDivergencia.CodigoProduto }}</td>
                            <td class="text-left">{{ DadosSemDivergencia.Nome }}</td>
                            <td class="text-left">{{ DadosSemDivergencia.CodigoAuxiliar }}</td>
                            <td class="text-left">{{ DadosSemDivergencia.Referencia }}</td>
                            <td class="text-left" v-if="movimentacaoPorSerial">
                              {{ DadosSemDivergencia.Serial == null ? '-' : DadosSemDivergencia.Serial }}
                            </td>
                            <td class="text-left">{{ DadosSemDivergencia.SaldoAnteriorERP }}</td>
                            <td v-if="DadosEmpresas.ProdutosControlaLote" class="text-left">
                              {{ DadosSemDivergencia.Lote == null ? '-' : DadosSemDivergencia.SaldoAnteriorLote }}
                            </td>
                            <td class="text-left">{{ DadosSemDivergencia.QuantidadeTotalConferencia }}</td>
                            <template v-if="DadosEmpresas.ProdutosControlaLote">
                              <td class="text-center">
                                {{ DadosSemDivergencia.Lote == null ? '-' : DadosSemDivergencia.Lote }}
                              </td>
                              <td class="text-center">
                                {{ DadosSemDivergencia.DataFabricacao == null ? '-' : formatarDataPTBR(DadosSemDivergencia.DataFabricacao) }}
                              </td>
                              <td class="text-center">
                                {{ DadosSemDivergencia.DataVencimento == null ? '-' : formatarDataPTBR(DadosSemDivergencia.DataVencimento) }}
                              </td>
                            </template>
                            <td class="text-left"
                              >R${{ DadosSemDivergencia.ValorProduto.toLocaleString('pt-br', { minimumFractionDigits: 2 }) }}</td
                            >
                          </tr>
                        </tbody>
                      </table>
                      <div class="d-flex row justify-content-left ml-1" v-if="totalPaginasSemDivergencia > 1">
                        <template v-for="(pagina, index) in totalPaginasSemDivergencia">
                          <button
                            v-if="verificarSeExibeBotaoPaginaSemDivergencia(pagina)"
                            :key="index"
                            class="btn btn-default"
                            :class="{ active: pagina == paginacaoSemDivergencia.paginaAtual, 'm-1': true }"
                            @click="paginacaoSemDivergencia.paginaAtual = pagina"
                          >
                            {{ pagina }}
                          </button>
                          <span v-else-if="verificarSeExibeBotaoPaginaSemDivergencia(pagina - 1)" :key="index" class="m-1"
                            >...</span
                          >
                        </template>
                      </div>
                      <div class="row mt-2">
                        <div class="col-12 col-md-4">
                          <small class="text-muted pl-2">
                            Qtde. de Registros:
                            <b>{{ DadosConferenciaBalanco.TotalSemDivergencias }}</b>
                          </small>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="table-responsive mb-3"
                    :style="'overflow-y: auto; overflow-x: hidden;'"
                    v-if="DadosConferenciaBalanco.DadosSemDivergenciaConferenciaBalanco.length <= 0"
                  >
                    <label class="margin-left">Nenhum produto encontrado.</label>
                  </div>
                </toogle-panel>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-12 col-md-12 mt-3 mt-md-0">
                <div class="float-right">
                  <button id="button-voltar" class="btn btn-default" data-toggle="modal" @click="voltarParaListagem">
                    Voltar </button
                  >&nbsp;
                  <button id="button-gerar-excel" class="btn btn-secondary" @click="exportarCSV"> Gerar Excel </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
  import Vue from 'vue';
  import swal from '@/common/alerts';
  import loading from '@/common/loading';
  import dateUtils from '@/common/date';
  import { EventBus } from '@/common/eventBus';
  import tooglePanel from '@/components/TooglePanel.vue';

  export default {
    name: 'RelatorioDeCriticas',
    components: {
      tooglePanel,
    },
    data() {
      return {
        tipoDeItem: 0,
        idBalanco: 0,
        descricaoBalanco: '',
        DadosConferenciaBalanco: {
          DadosComDivergenciaConferenciaBalanco: [],
          DadosSemDivergenciaConferenciaBalanco: [],
        },
        DadosEmpresas: {},
        paginacaoDivergente: {
          paginaAtual: 1,
        },
        paginacaoSemDivergencia: {
          paginaAtual: 1,
        },
        registrosPorPagina: 10,
        movimentacaoPorSerial: true
      };
    },
    created() {
      var self = this;
      self.DadosEmpresas = this.$route.params.empresas;
      self.descricaoBalanco = this.$route.params.descricaoBalanco;
      self.idBalanco = this.$route.params.idBalanco;

      this.carregaDadosDoRalatorioDeCritica().then(() => {});
    },
    mounted() {},
    watch: {
      totalPaginasComDivergencia(newVal) {
        var self = this;
        if (self.paginacaoDivergente.paginaAtual > newVal) self.paginacaoDivergente.paginaAtual = 1;
      },
      totalPaginasSemDivergencia(newVal) {
        var self = this;
        if (self.paginacaoSemDivergencia.paginaAtual > newVal) self.paginacaoSemDivergencia.paginaAtual = 1;
      },
    },

    computed: {
      isLoading() {
        return this.$store.state.uiState.isLoading;
      },
      totalPaginasComDivergencia() {
        var self = this;
        return Math.ceil(self.DadosConferenciaBalanco.DadosComDivergenciaConferenciaBalanco.length / self.registrosPorPagina);
      },
      totalPaginasSemDivergencia() {
        var self = this;

        return Math.ceil(self.DadosConferenciaBalanco.DadosSemDivergenciaConferenciaBalanco.length / self.registrosPorPagina);
      },
      indexInicialComDivergencia() {
        var self = this;
        return self.registrosPorPagina * (self.paginacaoDivergente.paginaAtual - 1);
      },
      indexFinalComDivergencia() {
        var self = this;
        var indexFinal = self.registrosPorPagina * (self.paginacaoDivergente.paginaAtual - 1) + self.registrosPorPagina;

        return indexFinal;
      },
      indexInicialSemDivergencia() {
        var self = this;
        return self.registrosPorPagina * (self.paginacaoSemDivergencia.paginaAtual - 1);
      },
      indexFinalSemDivergencia() {
        var self = this;
        var indexFinal = self.registrosPorPagina * (self.paginacaoSemDivergencia.paginaAtual - 1) + self.registrosPorPagina;

        return indexFinal;
      },
    },

    methods: {
      async carregaDadosDoRalatorioDeCritica() {
        loading.exibir();
        var self = this;

        return await this.$store
          .dispatch('balanco/ObterDadosConferenciaBalanco', {
            idBalanco: self.idBalanco,
            movimentacaoPorSerial: self.movimentacaoPorSerial
          })
          .then((jsonResponse) => {
            this.DadosConferenciaBalanco = jsonResponse;
            this.movimentacaoPorSerial = this.DadosConferenciaBalanco.MovimentacaoPorSerial
            return Promise.resolve(jsonResponse);
          })
          .catch((erro) => {
            let mensagemErro = '';

            if (erro.response) {
              if (erro.response.status == 400) mensagemErro = erro.data;
              else mensagemErro = erro.message;
            } else mensagemErro = erro;

            swal.exibirMensagemErro(mensagemErro);
            return Promise.reject(mensagemErro);
          })
          .finally(() => {
            loading.ocultar();
          });
      },
      verificarSeExibeBotaoPaginaComDivergencia(pagina) {
        var self = this;
        return (
          self.paginacaoDivergente.paginaAtual == pagina ||
          self.paginacaoDivergente.paginaAtual + 1 == pagina ||
          self.paginacaoDivergente.paginaAtual - 1 == pagina ||
          self.totalPaginas == pagina ||
          self.totalPaginas - 1 == pagina ||
          1 == pagina ||
          2 == pagina
        );
      },
      verificarSeExibeBotaoPaginaSemDivergencia(pagina) {
        var self = this;
        return (
          self.paginacaoSemDivergencia.paginaAtual == pagina ||
          self.paginacaoSemDivergencia.paginaAtual + 1 == pagina ||
          self.paginacaoSemDivergencia.paginaAtual - 1 == pagina ||
          self.totalPaginas == pagina ||
          self.totalPaginas - 1 == pagina ||
          1 == pagina ||
          2 == pagina
        );
      },
      voltarParaListagem() {
        this.$router.push({ name: 'PainelBalancos' });
      },
      exportarCSV: function () {
        var self = this;

        this.carregaDadosDoRalatorioDeCritica(self.idBalanco).then((data) => {
          const nomeArquivo = `RelatorioConferenciaBalanço_Nº${self.idBalanco}.xls`;

          var table = `<h2>Relatório de Conferência do Balanço</h2>
        <h3>Balanço: ${self.idBalanco} - ${self.descricaoBalanco}</h3>

        <h3>
          <ul class="circle">
            <li>${data.TotalProdutos} ${data.TotalProdutos <= 1 ? 'produto encontrado. </li>' : 'produtos encontrados. </li>'}
            <li>${data.TotalDivergente} ${
            data.TotalDivergente <= 1
              ? 'produto com divergência encontrada.</li>'
              : 'produtos com divergências encontradas. </li>'
          }
            <li>${data.EntradasExecutadas} ${
            data.EntradasExecutadas <= 1 ? 'entrada executada ' : 'entradas executadas '
          }(quantidade do balanço maior que a quantidade do ERP).</li>
            <li>${data.SaidasExecutadas} ${
            data.SaidasExecutadas <= 1 ? 'saída executada ' : 'saídas executadas '
          }(quantidade do balanço menor que a quantidade do ERP).</li>
          </ul>
        </h3>
        <h3 visible="${data.DadosComDivergenciaConferenciaBalanco.length > 0 ? true : false}">> Produtos Divergentes </h3>`;

          if (data.DadosComDivergenciaConferenciaBalanco.length > 0) {
            const linhas = data.DadosComDivergenciaConferenciaBalanco.map((i) => {
              return `
            <tr>
              <td>${i.CodigoProduto}</td>
              <td>${i.Nome}</td>
              <td>${i.CodigoAuxiliar}</td>
              <td>${i.Referencia}</td>
              ${self.movimentacaoPorSerial ? (i.Serial == null ? '<td>-</td>' : '<td>' + i.Serial + '</td>') : ''}
              <td>${i.SaldoAnteriorERP}</td>
              ${self.DadosEmpresas.ProdutosControlaLote ? (i.Lote == null ? '<td>-</td>' : '<td>' + i.SaldoAnteriorLote + '</td>') : ''}
              <td>${i.QuantidadeTotalConferencia}</td>
              ${self.DadosEmpresas.ProdutosControlaLote ? (i.Lote == null ? '<td>-</td>' : '<td>' + i.Lote + '</td>') : ''}
              ${
                self.DadosEmpresas.ProdutosControlaLote
                  ? i.DataFabricacao == null
                    ? '<td>-</td>'
                    : '<td>' + self.formatarDataPTBR(i.DataFabricacao) + '</td>'
                  : ''
              }
              ${
                self.DadosEmpresas.ProdutosControlaLote
                  ? i.DataVencimento == null
                    ? '<td>-</td>'
                    : '<td>' + self.formatarDataPTBR(i.DataVencimento) + '</td>'
                  : ''
              }
              <td>${i.TipoAjuste}</td>
              <td>${i.QuantidadeAjuste}</td>
              <td>R$${i.ValorProduto}</td>
              <td>R$${i.ValorDivergente}</td>
            </tr>`;
            }).join('');

            table = `${table}
          <table>
            <thead>
              <tr>
                <th>Código</th>
                <th>Produto</th>
                <th>Código Auxiliar</th>
                <th>Referência</th>
                ${self.movimentacaoPorSerial ? `<th>${self.DadosConferenciaBalanco.LegendaControle}</th>` : ''}
                <th>Saldo ERP</th>
                ${self.DadosEmpresas.ProdutosControlaLote ? '<th>Saldo Lote</th>' : ''}
                <th>Quantidade Balanço</th>
                ${self.DadosEmpresas.ProdutosControlaLote ? '<th>Lote</th>' : ''}
                ${self.DadosEmpresas.ProdutosControlaLote ? '<th>Data de Fabricação</th>' : ''}
                ${self.DadosEmpresas.ProdutosControlaLote ? '<th>Data de Validade</th>' : ''}
                <th>Tipo de Ajuste</th>
                <th>Quantidade</th>
                <th>Valor Produto</th>
                <th>Valor Divergente</th>
              </tr>
            <thead>
            <tbody>${linhas}</tbody>
          </table>`;
          }

          if (data.DadosSemDivergenciaConferenciaBalanco.length > 0) {
            const linhas = data.DadosSemDivergenciaConferenciaBalanco.map((i) => {
              return `
            <tr>
              <td>${i.CodigoProduto}</td>
              <td>${i.Nome}</td>
              <td>${i.CodigoAuxiliar}</td>
              <td>${i.Referencia}</td>
              ${self.movimentacaoPorSerial ? (i.Serial == null ? '<td>-</td>' : '<td>' + i.Serial + '</td>') : ''}
              <td>${i.SaldoAnteriorERP}</td>
              ${self.DadosEmpresas.ProdutosControlaLote ? (i.Lote == null ? '<td>-</td>' : '<td>' + i.SaldoAnteriorLote + '</td>') : ''}
              <td>${i.QuantidadeTotalConferencia}</td>
              ${self.DadosEmpresas.ProdutosControlaLote ? (i.Lote == null ? '<td>-</td>' : '<td>' + i.Lote + '</td>') : ''}
              ${
                self.DadosEmpresas.ProdutosControlaLote
                  ? i.DataFabricacao == null
                    ? '<td>-</td>'
                    : '<td>' + self.formatarDataPTBR(i.DataFabricacao) + '</td>'
                  : ''
              }
              ${
                self.DadosEmpresas.ProdutosControlaLote
                  ? i.DataVencimento == null
                    ? '<td>-</td>'
                    : '<td>' + self.formatarDataPTBR(i.DataVencimento) + '</td>'
                  : ''
              }
              <td>R$${i.ValorProduto}</td>
            </tr>`;
            }).join('');

            table = `${table}
          <br>

          <h3 visible="${data.DadosSemDivergenciaConferenciaBalanco.length > 0 ? true : false}">> Produtos sem Divergência</h3>
          <table>
            <thead>
              <tr>
                <th>Código</th>
                <th>Produto</th>
                <th>Código Auxiliar</th>
                <th>Referência</th>
                ${self.movimentacaoPorSerial ? `<th>${self.DadosConferenciaBalanco.LegendaControle}</th>` : ''}
                <th>Saldo ERP</th>
                ${self.DadosEmpresas.ProdutosControlaLote ? '<th>Saldo Lote</th>' : ''}
                <th>Quantidade Balanço</th>
                ${self.DadosEmpresas.ProdutosControlaLote ? '<th>Lote</th>' : ''}
                ${self.DadosEmpresas.ProdutosControlaLote ? '<th>Data de Fabricação</th>' : ''}
                ${self.DadosEmpresas.ProdutosControlaLote ? '<th>Data de Validade</th>' : ''}
                <th>Valor Produto</th>
              </tr>
            <thead>
            <tbody>${linhas}</tbody>
          </table>`;
          }

          self.download(table, nomeArquivo);
          return Promise.resolve();
        });
      },
      formatarDataPTBR(data) {
        if (data != '') return dateUtils.toStringPtBr(data);
      },
      download(body, nomeArquivo, nomeAba) {
        nomeAba = nomeAba || 'Relatório';
        var tableContent = `<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40">
      <head>
        <!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>${nomeAba}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]-->
        <meta charset="UTF-8"/>
        <style>.int{ mso-number-format:"0";}</style>
      </head>
      <body>${body}</body>
      </html>`;
        if (window.navigator.msSaveOrOpenBlob) {
          let blob = new Blob([tableContent], {
            type: 'application/vnd.ms-excel',
          });
          window.navigator.msSaveBlob(blob, nomeArquivo);
        } else {
          let blob = new Blob([tableContent], {
            type: 'application/vnd.ms-excel',
          });
          var elem = window.document.createElement('a');
          const data = window.URL.createObjectURL(blob);
          elem.href = data;
          elem.download = nomeArquivo;
          document.body.appendChild(elem);
          elem.click();
          document.body.removeChild(elem);
          setTimeout(function () {
            window.URL.revokeObjectURL(data);
          }, 100);
        }
      },
    },
  };
</script>

<style scoped>
  .card-margin {
    margin-top: 25px;
  }

  .margin-select {
    margin: 20px 0px;
  }

  .margin-descricao {
    margin: 20px -20px;
    cursor: pointer;
    color: #333;
    font-family: NeoSansPro-Regular, Verdana, sans-serif;
  }

  .text-danger {
    color: red;
    font-weight: bolder;
  }

  .margin-left {
    margin-left: 25px;
  }

  .lmx-tabela-relatorio thead,
  tfoot {
    background-color: #5b2e90;
    color: white;
  }

  .lmx-tabela-relatorio tbody {
    font-size: 90%;
  }

  .lmx-tela-relatorio-div-nenhum-registro {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .button {
    font-weight: bold;
    text-decoration: underline;
    background-color: white;
    border: none;
    color: black;
    padding: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    cursor: pointer;
    outline: none;
  }

  .container-fluid {
    overflow-x: hidden;
  }

  ul {
    list-style-type: none;
  }
</style>
